<template>
    
    <b-modal 
        v-model="Tickets.compose.active" 
        :title="$t('tickets.header')"
        footer-class="d-flex justify-content-between"
        body-class="p-0"
        centered
        @hide="feedBackWillHide"
   >
        
        <b-row class="mt-1 pl-1 pr-1">
            <b-col v-for="(subject, index) in Tickets.subjects.items" :key="index" md="6" cols="12" class='mb-1'>
                <div :class="{
                     'card-picker' : true,
                     'active' : subject.id === composeData.subject ? true : false,
                     'h-100' : true
                    }" @click='composeData.subject = subject.id'>
                    <feather-icon :icon="subject.icon" size="32" :class="{
                       'text-muted' : subject.id === composeData.subject ? false : true,
                       'text-white' : subject.id === composeData.subject ? true : false
                    }" />
                    <p class="header">{{ $i18n.locale === 'ru' ? subject.title : subject.title_en || subject.title }}</p>
                    <span class="text">{{ $i18n.locale === 'ru' ? subject.description : subject.description_en || subject.description }}</span>
                </div>
            </b-col>
        </b-row>
        
        <b-form class="data-form">
            
            <b-row class="pl-1 pr-1 pb-1">
                <b-col  cols="12">
                    <template v-if='childs.length > 0'>
                        <div class="d-flex align-items-center form-group-with-label">
                            <label
                                for="category"
                                class="form-label p-0 m-0"
                            >{{$t('tickets.сall-category')}}: </label>
                            <v-select
                                v-model="composeData.child"
                                label="title"
                                class="flex-grow-1 email-to-selector border-0"
                                :close-on-select="true"
                                :options="childs"
                                input-id="category"
                              >

                                <template v-if="$i18n.locale === 'ru'" #option="{ title }">
                                  <span class="ml-50"> {{ title }}</span>
                                </template>

                                <template v-else #option="{ title_en }">
                                  <span class="ml-50"> {{ title_en }}</span>
                                </template>

                                <template v-if="$i18n.locale === 'ru'" #selected-option="{ title }">
                                  <span class="ml-50"> {{ title }}</span>
                                </template>

                                <template v-else #selected-option="{ title_en }">
                                  <span class="ml-50"> {{ title_en }}</span>
                                </template>

                            </v-select>
                        </div>
                    </template>  
                </b-col>
                
                <b-col  cols="12">
                    <template v-if="composeData.subject === 1 && composeData.child && listOfContracts.length > 0">
                        <div class="d-flex align-items-center form-group-with-label">
                            <label
                                for="contracts"
                                class="form-label p-0 m-0"
                            >{{$t('tickets.contract')}} ({{$t('tickets.optional')}}): </label>
                            <v-select
                                v-model="composeData.contract"
                                label="guuid"
                                class="flex-grow-1 email-to-selector border-0"
                                :close-on-select="true"
                                :options="listOfContracts"
                                input-id="contracts"
                              >

                                <template #option="{ guuid, ths }">
                                    <div class="d-flex justify-content-between ml-50">
                                        <p class="m-0">{{ guuid }}</p>
                                        <span class="text-muted font-small-2">{{ ths }} TH/s</span>
                                    </div>
                                </template>

                                <template #selected-option="{ guuid, ths  }">
                                    <div class="d-flex justify-content-between ml-50 w-100">
                                        <p class="m-0">№{{ guuid }}</p>
                                    </div>
                                </template>

                            </v-select>
                        </div>
                    </template>
                    <template v-if="composeData.subject === 2 && composeData.child && listOfContracts.length > 0">
                        <div class="d-flex align-items-center form-group-with-label">
                            <label
                                for="miner"
                                class="form-label p-0 m-0"
                            >{{$t('tickets.miner')}} ({{$t('tickets.optional')}}): </label>
                            <v-select
                                v-model="composeData.miner"
                                label="guuid"
                                class="flex-grow-1 email-to-selector border-0"
                                :close-on-select="true"
                                :options="listOfContracts"
                                input-id="miner"
                              >

                                <template #option="{ machine, target, serial_number }">
                                    <div class="d-flex align-items-center ml-0">
                                        <b-avatar
                                            size="lg"
                                            :src="machine.medias[0].urls.thumb"
                                        />
                                        <div style='margin-left: 22px;'>
                                            <p class="m-0">{{ machine.name }}</p>
                                            <span class="text-muted font-small-2">{{ serial_number }}</span>
                                        </div>
                                    </div>
                                </template>

                                <template #selected-option="{ machine, target, serial_number  }">
                                    <div class="d-flex ml-50 w-100">
                                        <b-avatar
                                            size="sm"
                                            :src="machine.medias[0].urls.thumb"
                                        />
                                        <p class="m-0 ml-25">{{ machine.name }}</p>
                                    </div>
                                </template>

                            </v-select>
                        </div>
                    </template>
                </b-col>
                
                <b-col v-if="composeData.child" cols="12">
                    <div class="message-editor form-group-with-label mt-0 p-0">
                        <quill-editor
                          id="quil-content"
                          v-model="composeData.message"
                          :options="editorOption"
                        />
                        <div
                          id="quill-toolbar"
                          class="d-flex border-0"
                        >
                          <!-- Add a bold button -->
                          <button class="ql-bold" />
                          <button class="ql-italic" />
                          <button class="ql-underline" />
                        </div>
                    </div>
                </b-col>
                
                <b-col v-if="composeData.attachments.length > 0" class="d-flex justify-content-start align-items-center flex-nowrap " style="padding: 0px; height: 75px; cursor: pointer; border-radius: 4px; margin: 0 12px; overflow-y: hidden; overflow-x: scroll;">
                    <div v-for="(media, index) in composeData.attachments" :key="index" @click="$event.stopPropagation()" :style="getMediaPlaceholderStyle(media)">

                        <div @click="prefereMediaDeleteAction( $event, index )" class="bg-white" style="position: absolute; text-align: center; line-height: 20px; pointer: cursor; right: 2px; top: 2px; width: 22px; height: 22px; border-radius: 50%;">
                            <feather-icon icon="XIcon" size="14" color="gray" />
                        </div>

                        <b-progress 
                            v-if="media.id < 0"
                            :value="media.progress.loaded" 
                            :max="media.progress.total" 
                            show-progress 
                            animated
                            style="width: 100%"
                        ></b-progress>

                    </div>
                </b-col>
                
            </b-row>
            
               
        </b-form>

        <template #modal-footer>
            <!-- Footer: Left Content -->
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="!composeData.message || !composeData.child"
                @click="send"
              >
                {{$t('tickets.btns.send')}}
              </b-button>
              <feather-icon
                v-if="composeData.child"
                icon="PaperclipIcon"
                size="17"
                class="ml-2 cursor-pointer"
                @click="preferMediaPickerAction"
              />
            </div>

<!--             Footer: Right Content 
            <div>

               Dropdown: More Actions 
              <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0"
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="17"
                    class="text-body"
                  />
                </template>

                <b-dropdown-item>
                  Add Label
                </b-dropdown-item>

                <b-dropdown-item>
                  Plain Text Mode
                </b-dropdown-item>

                <b-dropdown-divider />

                <b-dropdown-item>
                  Print
                </b-dropdown-item>
                <b-dropdown-item>
                  Check Spelling
                </b-dropdown-item>
              </b-dropdown>

               Discard Compose 
              <feather-icon
                icon="TrashIcon"
                size="17"
                class="ml-75 cursor-pointer"
              />
            </div>-->
        </template>


    </b-modal>
    
</template>

<script>

    import vSelect from 'vue-select'
    import Tickets from "@/modules/tickets"
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import { quillEditor } from 'vue-quill-editor'
    import { pickFile } from 'js-pick-file';
    import $utils from "@/modules/utils/"
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    
    export default {

        data() {
            return {
                Tickets,
                composeData: {
                    subject: null,
                    child: null,
                    contract: null,
                    miner: null,
                    message: null,
                    attachments: []
                },
                editorOption: {
                    modules: {
                      toolbar: '#quill-toolbar',
                    },
                    placeholder: this.$t('tickets.placeholders.your-message'),
                },
                listOfContracts: [],
                required
            }
        },
        methods: {
            
            getValueByVector( vector ) {
                for( var i = 0 ; i < this.Tickets.subjects.items.length; i++ ) {
                    var parent = this.Tickets.subjects.items[i];
                    if( parent.vector === vector ) return parent.id;
                    for( var j = 0; j < parent.childs.length; j++ ) {
                        var child = parent.childs[j];
                        if( child.vector === vector ) {
                            return child;
                        }
                    }
                }
            },
            
            send( args ) {
                
                this.$request.post("tickets.create", this.params ).then( rsp => {
                    this.Tickets.compose.active = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: this.$t('toasts.titles.ticket'),
                          text: this.$t('toasts.text.ticket'),
                          icon: 'CheckCircleIcon',
                          variant: 'success',
                        }
                    });
                    this.composeData = {
                        subject: null,
                        child: null,
                        contract: null,
                        miner: null,
                        message: null,
                        attachments: []
                    }
                });
                
            },
            
            getMediaPlaceholderStyle( media ) {
                
                return {
                    position: "relative",
                    display: 'flex',
                    alignItems: "center",
                    width: "75px",
                    minWidth: "75px",
                    height: "75px",
                    backgroundImage: `url(${media.thumb})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    borderRadius: "8px",
                    margin: "0 4px",
                    padding: "0 4px"
                }
                
            },
            
            prefereMediaDeleteAction(args, index ) {
                
                this.composeData.attachments.splice( index, 1 );
                args.stopPropagation()
                
            },
            
            preferMediaPickerAction( args ) {
                
                let options = { 
                    accept: '.jpg, .jpeg, .png', 
                    multiple: true 
                }
                
                pickFile(options).then(files => {
                     files.forEach(file => {
                         
                        $utils.preferBeforeUploadAction( file ).then( data => {
                            
                            let object = {
                                id: -1,
                                type: data.type,
                                file: file,
                                fileName: file.name,
                                size: file.size,
                                extension: file.name.split('.').pop(),
                                duration: data.duration,
                                thumb: data.thumb,
                                placeholder: data.placeholder,
                                width: data.width,
                                height: data.height,
                                progress: {
                                    loaded: 0,
                                    total: 0
                                }
                                
                            }
                            
                            this.composeData.attachments.push(object);
                            
                            this.$request.transfer("media.upload", {
                                type: data.type,
                                width: data.width,
                                height: data.height
                            }, file, ( progress ) => {
                                object.progress.total = progress.total;
                                object.progress.loaded = progress.loaded;
                            }).then( rsp => {
                                this.$set(object, "id", rsp.upload_id );
                            });
                            
                        }); 
                         
                         
                     });
                });
                
            },
            
            feedBackWillHide( args ) {
                this.$router.replace({
                    name: this.$route.name,
                    params: this.$route.params
                });
            },
            
            feath() {
                this.listOfContracts = [];
                this.is_loading = true;
                this.$request.get("contracts.feath", {
                    type: this.composeData.subject
                }).then(rsp => {
                    this.listOfContracts = rsp.list;
                    if( this.composeData.subject === 2 && this.$route.query.miner ) {
                        this.listOfContracts.forEach(item => {
                           if( item.id === parseInt(this.$route.query.miner) ) {
                               this.composeData.miner = item;
                           } 
                        });
                    } 
                    this.is_loading = false;
                })
            }
        },
        components: {
            vSelect,
            quillEditor,
            ValidationProvider,
            ValidationObserver
        },
        computed: {
            
            params() {
                
                return {
                    subject: this.composeData.child.id,
                    contract: this.composeData.contract ? this.composeData.contract.id : null,
                    miner: this.composeData.miner ? this.composeData.miner.id : null,
                    text: this.composeData.message
                }
                
            },
            
            childs() {
                if( this.composeData.subject ) {
                    return this.Tickets.subjects.items.find(item => item.id === this.composeData.subject).childs;
                }
                return [];                
            }
        },
        watch: {
            
            "$route.query.feedback" : function( val ) {
                if( val ) {
                    this.Tickets.compose.active = true;
                    this.composeData.subject = this.getValueByVector( this.$route.query.type );
                    this.composeData.child = this.getValueByVector(`${this.$route.query.type}:${this.$route.query.action}`);
                } else {
                    this.Tickets.compose.active = false;
                }
            },
            
            "composeData.subject" : function( val ) {
                this.composeData.child = null;
                this.feath();
            }
        },
        mounted() {

        }

    }

</script>

<style lang="scss">
    
    @import '@core/scss/vue/libs/vue-select.scss';
    @import "~@core/scss/base/pages/app-email.scss";
    @import '@core/scss/vue/libs/quill.scss';   
    
    .quill-editor {
        .ql-container.ql-snow {
          border: none !important;
        }
   }
</style>