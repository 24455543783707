var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.$t('tickets.header'),"footer-class":"d-flex justify-content-between","body-class":"p-0","centered":""},on:{"hide":_vm.feedBackWillHide},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","disabled":!_vm.composeData.message || !_vm.composeData.child},on:{"click":_vm.send}},[_vm._v(" "+_vm._s(_vm.$t('tickets.btns.send'))+" ")]),(_vm.composeData.child)?_c('feather-icon',{staticClass:"ml-2 cursor-pointer",attrs:{"icon":"PaperclipIcon","size":"17"},on:{"click":_vm.preferMediaPickerAction}}):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.Tickets.compose.active),callback:function ($$v) {_vm.$set(_vm.Tickets.compose, "active", $$v)},expression:"Tickets.compose.active"}},[_c('b-row',{staticClass:"mt-1 pl-1 pr-1"},_vm._l((_vm.Tickets.subjects.items),function(subject,index){return _c('b-col',{key:index,staticClass:"mb-1",attrs:{"md":"6","cols":"12"}},[_c('div',{class:{
                     'card-picker' : true,
                     'active' : subject.id === _vm.composeData.subject ? true : false,
                     'h-100' : true
                    },on:{"click":function($event){_vm.composeData.subject = subject.id}}},[_c('feather-icon',{class:{
                       'text-muted' : subject.id === _vm.composeData.subject ? false : true,
                       'text-white' : subject.id === _vm.composeData.subject ? true : false
                    },attrs:{"icon":subject.icon,"size":"32"}}),_c('p',{staticClass:"header"},[_vm._v(_vm._s(_vm.$i18n.locale === 'ru' ? subject.title : subject.title_en || subject.title))]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$i18n.locale === 'ru' ? subject.description : subject.description_en || subject.description))])],1)])}),1),_c('b-form',{staticClass:"data-form"},[_c('b-row',{staticClass:"pl-1 pr-1 pb-1"},[_c('b-col',{attrs:{"cols":"12"}},[(_vm.childs.length > 0)?[_c('div',{staticClass:"d-flex align-items-center form-group-with-label"},[_c('label',{staticClass:"form-label p-0 m-0",attrs:{"for":"category"}},[_vm._v(_vm._s(_vm.$t('tickets.сall-category'))+": ")]),_c('v-select',{staticClass:"flex-grow-1 email-to-selector border-0",attrs:{"label":"title","close-on-select":true,"options":_vm.childs,"input-id":"category"},scopedSlots:_vm._u([(_vm.$i18n.locale === 'ru')?{key:"option",fn:function(ref){
                    var title = ref.title;
return [_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(title))])]}}:{key:"option",fn:function(ref){
                    var title_en = ref.title_en;
return [_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(title_en))])]}},(_vm.$i18n.locale === 'ru')?{key:"selected-option",fn:function(ref){
                    var title = ref.title;
return [_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(title))])]}}:{key:"selected-option",fn:function(ref){
                    var title_en = ref.title_en;
return [_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(title_en))])]}}],null,true),model:{value:(_vm.composeData.child),callback:function ($$v) {_vm.$set(_vm.composeData, "child", $$v)},expression:"composeData.child"}})],1)]:_vm._e()],2),_c('b-col',{attrs:{"cols":"12"}},[(_vm.composeData.subject === 1 && _vm.composeData.child && _vm.listOfContracts.length > 0)?[_c('div',{staticClass:"d-flex align-items-center form-group-with-label"},[_c('label',{staticClass:"form-label p-0 m-0",attrs:{"for":"contracts"}},[_vm._v(_vm._s(_vm.$t('tickets.contract'))+" ("+_vm._s(_vm.$t('tickets.optional'))+"): ")]),_c('v-select',{staticClass:"flex-grow-1 email-to-selector border-0",attrs:{"label":"guuid","close-on-select":true,"options":_vm.listOfContracts,"input-id":"contracts"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                    var guuid = ref.guuid;
                    var ths = ref.ths;
return [_c('div',{staticClass:"d-flex justify-content-between ml-50"},[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(guuid))]),_c('span',{staticClass:"text-muted font-small-2"},[_vm._v(_vm._s(ths)+" TH/s")])])]}},{key:"selected-option",fn:function(ref){
                    var guuid = ref.guuid;
                    var ths = ref.ths;
return [_c('div',{staticClass:"d-flex justify-content-between ml-50 w-100"},[_c('p',{staticClass:"m-0"},[_vm._v("№"+_vm._s(guuid))])])]}}],null,false,3209885768),model:{value:(_vm.composeData.contract),callback:function ($$v) {_vm.$set(_vm.composeData, "contract", $$v)},expression:"composeData.contract"}})],1)]:_vm._e(),(_vm.composeData.subject === 2 && _vm.composeData.child && _vm.listOfContracts.length > 0)?[_c('div',{staticClass:"d-flex align-items-center form-group-with-label"},[_c('label',{staticClass:"form-label p-0 m-0",attrs:{"for":"miner"}},[_vm._v(_vm._s(_vm.$t('tickets.miner'))+" ("+_vm._s(_vm.$t('tickets.optional'))+"): ")]),_c('v-select',{staticClass:"flex-grow-1 email-to-selector border-0",attrs:{"label":"guuid","close-on-select":true,"options":_vm.listOfContracts,"input-id":"miner"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                    var machine = ref.machine;
                    var target = ref.target;
                    var serial_number = ref.serial_number;
return [_c('div',{staticClass:"d-flex align-items-center ml-0"},[_c('b-avatar',{attrs:{"size":"lg","src":machine.medias[0].urls.thumb}}),_c('div',{staticStyle:{"margin-left":"22px"}},[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(machine.name))]),_c('span',{staticClass:"text-muted font-small-2"},[_vm._v(_vm._s(serial_number))])])],1)]}},{key:"selected-option",fn:function(ref){
                    var machine = ref.machine;
                    var target = ref.target;
                    var serial_number = ref.serial_number;
return [_c('div',{staticClass:"d-flex ml-50 w-100"},[_c('b-avatar',{attrs:{"size":"sm","src":machine.medias[0].urls.thumb}}),_c('p',{staticClass:"m-0 ml-25"},[_vm._v(_vm._s(machine.name))])],1)]}}],null,false,1602130409),model:{value:(_vm.composeData.miner),callback:function ($$v) {_vm.$set(_vm.composeData, "miner", $$v)},expression:"composeData.miner"}})],1)]:_vm._e()],2),(_vm.composeData.child)?_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"message-editor form-group-with-label mt-0 p-0"},[_c('quill-editor',{attrs:{"id":"quil-content","options":_vm.editorOption},model:{value:(_vm.composeData.message),callback:function ($$v) {_vm.$set(_vm.composeData, "message", $$v)},expression:"composeData.message"}}),_c('div',{staticClass:"d-flex border-0",attrs:{"id":"quill-toolbar"}},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-italic"}),_c('button',{staticClass:"ql-underline"})])],1)]):_vm._e(),(_vm.composeData.attachments.length > 0)?_c('b-col',{staticClass:"d-flex justify-content-start align-items-center flex-nowrap ",staticStyle:{"padding":"0px","height":"75px","cursor":"pointer","border-radius":"4px","margin":"0 12px","overflow-y":"hidden","overflow-x":"scroll"}},_vm._l((_vm.composeData.attachments),function(media,index){return _c('div',{key:index,style:(_vm.getMediaPlaceholderStyle(media)),on:{"click":function($event){return $event.stopPropagation()}}},[_c('div',{staticClass:"bg-white",staticStyle:{"position":"absolute","text-align":"center","line-height":"20px","pointer":"cursor","right":"2px","top":"2px","width":"22px","height":"22px","border-radius":"50%"},on:{"click":function($event){return _vm.prefereMediaDeleteAction( $event, index )}}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"14","color":"gray"}})],1),(media.id < 0)?_c('b-progress',{staticStyle:{"width":"100%"},attrs:{"value":media.progress.loaded,"max":media.progress.total,"show-progress":"","animated":""}}):_vm._e()],1)}),0):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }