

    import $request from "@/modules/request/"

    export default {
            
        subjects: {
            items: [],
            get() {
                $request.get("tickets.subjects").then( rsp => this.items = rsp.items );
            }
        },
        
        compose: {
            active: false,
            form: {
                title: null,
                subject: null,
                message: null
            }
        },
        
        listOf: [],
        get() {}
            
            
    }